.delivery-custom-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
  }
  
  .delivery-custom-checkbox.checked {
    background-color: white; /* Change color when checked */
    border-color: #e96125; /* Match border color */
  }
  
  .delivery-custom-checkbox.checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px; /* Size of the inner checkmark */
    height: 10px; /* Size of the inner checkmark */
    background-color:#e96125; /* Checkmark color */
    border-radius: 50%; /* Make it circular */
    transform: translate(-50%, -50%);
  }
  