/* Optional: Add a bottom margin to the fixed banner to prevent content overlap */
.fixed-banner {
  margin-bottom: 10px; /* Optional margin between the banner and content below */
}
@media (max-width: 1024px) and (min-width: 769px) {
    .wrapper {
        padding-top: 80px; 
    }


}
@media (max-width: 768px) {
  .wrapper {
    padding-top: 180px; /* Adjust based on the combined height of your header and banner */
  }
  .fixed-banner {
    position: fixed; /* Fixed position to ensure it stays in place */
    top: 0; /* Align to the top of the viewport */
    left: 0; /* Align to the left of the viewport */
    right: 0; /* Align to the right of the viewport */
    z-index: 100; /* Ensure it's above other elements */
    width: 100%; /* Make sure it spans the full width */
    background-color: white; /* Background color for better visibility */
    overflow: hidden; /* Prevent overflow */
    margin-top: 60px;
  }
}
